import { useState, useEffect } from "react";

const getWindowDimensions = () => {
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
    return {
      windowWidth,
      windowHeight,
    };
  } else return { windowWidth: 0, windowHeight: 0 };
};

const useWindowDimensions = () => {
  const isBrowser = typeof window !== "undefined";

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    if (isBrowser) window.addEventListener("resize", handleResize);
    return () => {
      if (isBrowser) window.removeEventListener("resize", handleResize);
    };
  });

  return windowDimensions;
};

export default useWindowDimensions;
