import React, { useEffect, useState, useRef } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import anime from "animejs";
import theme from "../gatsby-theme-material-ui-top-layout/theme";

const styles = {
  root: {
    zIndex: theme.zIndex.modal,
    mixBlendMode: "exclusion",
  },
  fullscreen: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  progressText: {
    color: "#fff",
    position: "absolute",
  },
};

const Loading = (props) => {
  let { fullscreen, progress, ...otherProps } = props;
  if (fullscreen === undefined) fullscreen = true;

  // LERP PROGRESS - check this later
  const [lerpProgress, setLerpProgress] = useState(0);
  const lerpAnimRef = useRef();
  useEffect(() => {
    if (lerpAnimRef.current) lerpAnimRef.current.pause();

    const obj = {
      val: lerpProgress,
    };

    lerpAnimRef.current = anime({
      targets: obj,
      val: progress,
      easing: "linear",
      duration: 100,
      update: () => {
        setLerpProgress(obj.val);
      },
    });
  }, [progress]);

  return (
    <Box
      sx={fullscreen ? { ...styles.root, ...styles.fullscreen } : styles.root}
    >
      <CircularProgress size={180} thickness={0.5} {...otherProps} />
      <Typography variant="body1" sx={styles.progressText}>
        {lerpProgress.toLocaleString("en", { style: "percent" })}
      </Typography>
    </Box>
  );
};

export default Loading;
