import { useState, useEffect, useRef } from "react";
import { Group, Vector3 } from "three";

// Constants
const CAM_SPEED = 0.05;
const CAM_DAMPER = 0.95;

const useMouseParallax = () => {
  const [camera, setCamera] = useState();
  const [scene, setScene] = useState();
  const [updateMouseParallax, setUpdateMouseParallax] = useState(() => {});
  // const [onMouseFreeze, setOnMouseFreeze] = useState();
  const camGroup = useRef(new Group());
  const vpReference = useRef();
  const targetRef = useRef(new Vector3());
  const mousePos = useRef({ x: 0, y: 0 });

  const initiateMouseParallax = ({ scene, camera, viewport, target }) => {
    // console.log("initiated mouse parallax");
    setScene(scene);
    setCamera(camera);
    vpReference.current = viewport;
    target.current = new Vector3().fromArray(target);

    setUpdateMouseParallax(() => {
      return () => {
        camera.position.x +=
          (mousePos.current.x - camera.position.x) * CAM_SPEED;
        camera.position.y +=
          (-mousePos.current.y - camera.position.y) * CAM_SPEED;
        camera.lookAt(new Vector3().fromArray(target));
      };
    });
  };

  // setup camGroup
  useEffect(() => {
    if (!camera || !scene) return;

    // create camGroup and copy transforms from camera
    const subCam = camera.clone();
    camGroup.current.position.copy(subCam.position);
    camGroup.current.rotation.copy(camera.rotation);

    // remove camera from parent
    const parent = camera.parent;
    camera.removeFromParent();

    // add camera to camGroup and make it's orientation 0,0,0
    camGroup.current.add(camera);
    camera.rotateY(180 * (Math.PI / 180));
    camera.position.copy(new Vector3());

    parent.add(camGroup.current);
  }, [camera, scene, camGroup]);

  // setup parallax
  useEffect(() => {
    const onDocumentMouseMove = (ev) => {
      if (!vpReference?.current) return;
      mousePos.current.x =
        ((ev.clientX - vpReference.current.clientWidth / 2) / 2) * CAM_DAMPER;
      mousePos.current.y =
        ((ev.clientY - vpReference.current.clientHeight / 2) / 2) * CAM_DAMPER;

      // setTimeout(() => {});
    };

    document.addEventListener("mousemove", onDocumentMouseMove);

    return () => {
      document.removeEventListener("mousemove", onDocumentMouseMove);
    };
  });

  return {
    camGroup: camGroup.current,
    updateMouseParallax,
    // setOnMouseFreeze,
    initiateMouseParallax,
  };
};

export default useMouseParallax;
