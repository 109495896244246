import React, {
  useState,
  useEffect,
  useRef,
  createRef,
  forwardRef,
} from "react";
import { graphql } from "gatsby";
import Loading from "../components/loading";
import PageLayout from "../components/pageLayout";
import { genHelmet } from "../actions/baseActions";
import useSceneLoader from "../hooks/useSceneLoader";
import useWindowDimensions from "../hooks/useWindowDimensions";
import useMouseParallax from "../hooks/useMouseParallax";
import { Color } from "three";
import { Typography, Grid, Paper, Box } from "@mui/material";
import anime from "animejs";
import Footer from "../components/footer";
import Markdown from "../components/markdown";
import LogoCollection from "../components/logoCollection";
import ThreeAnimationHelper from "../components/threeAnimationHelper";

import theme from "../gatsby-theme-material-ui-top-layout/theme";

const styles = {
  root: {},
  viewport: {
    position: "fixed",
    top: 0,
    left: 0,
    background: "transparent",
    width: "100vw",
    height: "100vh",
    opacity: 0,
    transition: "opacity 0.5s ease-in-out",
  },
  scrollDiv: {
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    color: "#fff",
    background: "transparent",
  },
  slide: {
    width: "100vw",
    minHeight: "100vh",
    display: "flex",
    mixBlendMode: "exclusion",
    padding: theme.spacing(3),
  },
  markdown: {},
  whiteSlide: {
    color: "#000",
    mixBlendMode: "initial",
    background: "rgba(255,255,255,0.9)",
  },
  blurPaper: {
    padding: {
      xs: theme.spacing(2),
      md: theme.spacing(4),
    },
    "& > div > * ": {
      margin: 0,
    },
    "& > div > h3": {
      paddingBottom: theme.spacing(3),
    },
  },
  paperWrapper: {
    padding: theme.spacing(6),
    mixBlendMode: "initial",
  },
  footerSlide: {
    background: "rgba(255,255,255)",
    padding: 0,
  },
  logoWrapper: {
    zIndex: 1, // removes issue with fixed paper text areas
    padding: theme.spacing(4),
    paddingLeft: {
      xs: theme.spacing(1),
      md: theme.spacing(4),
    },
    paddingRight: {
      xs: theme.spacing(1),
      md: theme.spacing(4),
    },
  },
  fixedWrapper: {
    position: "fixed",

    height: "100%",
    top: 0,
  },
  noWebGL: {
    position: "fixed",
    top: 0,
    left: 0,
    margin: 0,
    padding: theme.spacing(10),
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};

const introAnimObj = {
  camGroup: {
    initial: {
      x: 100000,
      z: 87396,
      camTargetX: 100000,
      camTargetY: 0,
      camTargetZ: 0,
    },
    keyframes: {
      x: [{ value: 100000 }, { value: 252 }, { value: 252 }],
      z: [{ value: 87396 }, { value: 12516 }],
      camTargetX: [{ value: 225 }, { value: 225 }, { value: 225 }],
      camTargetY: [{ value: 1666 }, { value: 1666 }, { value: 1666 }],
      camTargetZ: [{ value: 8547 }, { value: 8547 }, { value: 8547 }],
    },
    duration: 5000,
  },
};

const scrollAnimObj = {
  camGroup: {
    initial: {
      // set these up to match the final goals of the introAnimObj
      x: introAnimObj.camGroup.keyframes.x[
        introAnimObj.camGroup.keyframes.x.length - 1
      ].value,
      z: introAnimObj.camGroup.keyframes.z[
        introAnimObj.camGroup.keyframes.z.length - 1
      ].value,
    },
    keyframes: {
      x: [{ value: 1250 }],
      z: [{ value: 6450 }],
    },
    duration: 1000, // arbitrary
  },
};

const FINAL_CAM_TARGET = [225, 1666, 8547];

const Digital = (props) => {
  const pageData = props.data.contentfulPage;
  const { contentCollection } = pageData;
  const vpReference = useRef();
  const animTimeoutRef = useRef();
  const { windowHeight } = useWindowDimensions();

  // intro animation
  const [introAnimationComplete, setIntroAnimationComplete] = useState(false);
  const [introAnimationInitiated, setIntroAnimationInitated] = useState(false);

  // scroll
  const [scrollValue, setScrollValue] = useState(0);
  const [scrollTotal, setScrollTotal] = useState(100000);
  const scrollRef = useRef(); // for the scrollable div
  const prevScrollValue = useRef();
  const scrollTimelineRef = useRef();

  // slide refs for holds
  const slideRefArray = useRef(new Array(contentCollection.length));
  const [slideYPosArray, setSlideYPosArray] = useState(
    new Array(contentCollection.length)
  );
  const [slideOpacityArray, setSlideOpacityArray] = useState(
    new Array(contentCollection.length).fill(1)
  );
  const [fixedSlideArray, setFixedSlideArray] = useState(
    new Array(contentCollection.length).fill(false)
  );

  // set the scroll total
  useEffect(() => {
    if (!introAnimationComplete) return;
    if (!scrollRef?.current) return;
    setScrollTotal(scrollRef.current.offsetHeight);
  }, [introAnimationComplete]);

  // useEffect(() => {
  //   console.log("scrollTotal changed:", scrollTotal);
  // }, [scrollTotal]);

  // useEffect(() => {
  //   console.log("scrollValue changed:", scrollValue);
  // }, [scrollValue]);

  // mouse parallax
  const { camGroup, updateMouseParallax, initiateMouseParallax } =
    useMouseParallax();

  // setup Scene
  const {
    isLoaded,
    render,
    progress,
    camera,
    interesect,
    totalAssets,
    loadedAssets,
    scene,
    isAnimating,
    setUpdate,
    startAnimation,
    pauseAnimation,
    webGLSupported,
  } = useSceneLoader({
    sceneId: "DE15063E-CDA9-4532-92A3-AFE56A5B2B62", // ETD showroom
    vpReference,
    startAnimation: false,
    controlsCenter: [0, 0, 0],
    renderSettings: {
      type: "WEBGL", // ENUMs: 'RECT_LIGHT', 'SHADER', 'TOON', 'GLASS', WEBGL
      bloomParams: {
        // exposure: 1,
        bloomStrength: 0.65,
        bloomThreshold: 0,
        bloomRadius: 0.5,
      },
      // controlsType: "ORBIT_CONTROLS",
      orbitControlsSettings: {
        center: [230.158, 1673.586, 8578.089],
        minDistance: 500,
        maxDistance: 5000,
        panSpeed: 0,
        maxPolarAngle: Math.PI * 0.55,
        // minAzimuthAngle: Math.PI,
        // maxAzimuthAngle: Math.PI * 0.5,
        // outputEncoding: 3001 //3007 //THREE.GammaEncoding //THREE.sRGBEncoding
      },
      // toonBorder: {
      //   color: [255,255,255],
      //   thickness: 0.005
      // },
    },
  });

  // set update
  useEffect(() => {
    if (!updateMouseParallax || !introAnimationComplete) return;
    setUpdate(() => {
      updateMouseParallax();
    });
  }, [updateMouseParallax, introAnimationComplete]);

  // fix scene background and initiate parallax
  useEffect(() => {
    // console.log(scene);
    if (!scene || !camera || !vpReference.current) return;
    scene.background = new Color(0xffffff);

    // setup Parallax Mouse
    initiateMouseParallax({
      scene,
      camera,
      viewport: vpReference.current,
      target: FINAL_CAM_TARGET,
    });
  }, [scene, camera, vpReference]);

  // setup scrolling
  const handleScroll = () => {
    setScrollValue(window.scrollY);

    if (slideRefArray.current) {
      let tempYPosArray = slideYPosArray.slice();
      let tempFixedSlideArray = fixedSlideArray.slice();
      let tempSlideOpacityArray = slideOpacityArray.slice();
      slideRefArray.current.forEach((val, index) => {
        const { y } = slideRefArray.current[index].getBoundingClientRect();

        // store y to remove the flick of discrete scrolling
        if (y < 0 && tempYPosArray[index] === undefined) {
          tempYPosArray[index] = y;
        }

        // handle opacity
        if (y < 0) {
          // set in relationship to screen height
          const START = 0.35;
          const FINISH = 0.75;

          const opacity = 1 - (-y / windowHeight - START) / (FINISH - START);

          const clampedOpacity = Math.max(0, Math.min(opacity, 1));

          tempSlideOpacityArray[index] = clampedOpacity;
        } else {
          tempSlideOpacityArray[index] = 1;
        }

        if (y <= 0 && !fixedSlideArray[index]) {
          tempFixedSlideArray[index] = true;
        } else if (y > 0 && fixedSlideArray[index]) {
          tempFixedSlideArray[index] = false;
        }
      });
      setSlideYPosArray(tempYPosArray);
      setFixedSlideArray(tempFixedSlideArray);
      setSlideOpacityArray(tempSlideOpacityArray);
      // console.log(tempYPosArray);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  // start animation
  const startLocalAnim = () => {
    if (animTimeoutRef.current) clearTimeout(animTimeoutRef.current);

    // startAnimation();
    // animTimeoutRef.current = setTimeout(() => {
    //   pauseAnimation();
    // }, 1000);
  };
  useEffect(() => {
    if (!isLoaded) return;
    if (scrollValue === prevScrollValue.current) return;
    if (isAnimating) return;
    if (!introAnimationComplete) return;

    // startLocalAnim();

    prevScrollValue.current = scrollValue;
  }, [
    scrollValue,
    isLoaded,
    isAnimating,
    animTimeoutRef,
    introAnimationComplete,
  ]);

  // control intro animation
  useEffect(() => {
    if (!isLoaded || !startAnimation || introAnimationInitiated || !camGroup)
      return;

    // console.log("intro animation starting");
    setIntroAnimationInitated(true);

    // create animObject from object
    const animObj = {};
    Object.keys(introAnimObj).forEach((key) => {
      const { initial, keyframes, duration } = introAnimObj[key];

      animObj[key] = JSON.parse(JSON.stringify(initial)); // need to copy value to esnure reload works

      anime({
        targets: animObj[key],
        // keyframes,
        ...keyframes,
        // z: introAnimObj.camera.z[1],
        // x: introAnimObj.camera.x[1],
        easing: "easeInOutCubic",
        duration,
        update: () => {
          camGroup.position.setZ(animObj[key].z);
          camGroup.position.setX(animObj[key].x);
          camGroup.lookAt(
            animObj[key].camTargetX,
            animObj[key].camTargetY,
            animObj[key].camTargetZ
          );
        },
        complete: () => {
          // console.log("completing animation");
          setIntroAnimationComplete(true);
          // pauseAnimation();
        },
      });
    });
    // console.log(animObj);

    startAnimation();
  }, [
    isLoaded,
    camGroup,
    startAnimation,
    // pauseAnimation,
    introAnimationInitiated,
  ]);

  // setup scroll animation
  useEffect(() => {
    const animObj = {};
    scrollTimelineRef.current = {};
    Object.keys(scrollAnimObj).forEach((key) => {
      const { initial, keyframes, duration } = scrollAnimObj[key];
      animObj[key] = initial;
      scrollTimelineRef.current[key] = anime.timeline({
        easing: "easeOutCubic",
        autoplay: false,
        update: () => {
          camGroup.position.setZ(animObj[key].z);
          camGroup.position.setX(animObj[key].x);
        },
      });
      scrollTimelineRef.current[key].add({
        targets: animObj[key],
        ...keyframes,
        duration,
      });
    });
  }, [camGroup]);

  // control the scroll animation
  useEffect(() => {
    if (!camGroup) return;
    if (!introAnimationComplete) return;
    if (scrollValue === prevScrollValue.current) return;
    if (!scrollTimelineRef.current) return;

    const progress = scrollValue / scrollTotal;
    scrollTimelineRef.current.camGroup.seek(
      progress * scrollAnimObj.camGroup.duration
    );
  }, [
    scrollValue,
    prevScrollValue,
    camGroup,
    introAnimationComplete,
    scrollTimelineRef,
  ]);

  if (webGLSupported) {
    return (
      <>
        {process.env.NODE_ENV === "development" && (
          <ThreeAnimationHelper camera={camGroup} scene={scene} />
        )}
        {genHelmet(pageData, {
          type: "page",
          // siteData: pageData,
          path: props.path,
        })}
        <PageLayout location={props.location} hideFooter sx={styles.root}>
          {!isLoaded && <Loading progress={progress} />}
          <Box
            ref={vpReference}
            sx={{ ...styles.viewport, opacity: isLoaded ? 1 : undefined }}
            // style={{ opacity: 0 }}
            // style={intersect ? { cursor: "pointer" } : {}}
            // onClick={handleClick}
            // onTouchEnd={handleClick}
          />

          <Grid
            container
            sx={styles.scrollDiv}
            flexDirection="column"
            justifyContent="space-between"
            ref={scrollRef}
          >
            <Grid item container>
              <Grid
                item
                sx={{
                  ...styles.slide,
                  ...styles.noMixBlendMode,
                }}
                // className={clsx({
                //   [classes.slide]: true,
                //   [classes.noMixBlendMode]: true,
                // })}
                alignItems="center"
                justifyContent="center"
              >
                {/* <Typography variant="h2">BVA Digital</Typography> */}
              </Grid>
              {introAnimationComplete &&
                contentCollection.map((item, index) => {
                  // const { tagName, children } =
                  // item.markdown.childMarkdownRemark.htmlAst.children[0];
                  // console.log(item.markdown);
                  // const variant = tagName === "p" ? "h5" : tagName;
                  // const { value: text } = children[0];
                  return (
                    <Grid
                      item
                      ref={(el) => (slideRefArray.current[index] = el)}
                      xs={12}
                      container
                      style={{ height: "100vh" }} // used for slide scrolling
                      key={`item-${index}`}
                      justifyContent={
                        item.align === "right"
                          ? "flex-end"
                          : item.align === "left"
                          ? "flex-start"
                          : "center"
                      }
                    >
                      <Grid
                        item
                        xs={item.xs}
                        sm={item.sm}
                        md={item.md}
                        lg={item.lg}
                        xl={item.xl}
                        sx={{
                          ...styles.slide,
                          ...styles.paperWrapper,
                          ...(fixedSlideArray[index] && {
                            ...styles.fixedWrapper,
                          }),
                          top: slideYPosArray[index],
                          opacity: slideOpacityArray[index],
                        }}
                        // className={clsx({
                        //   [classes.slide]: true,
                        //   [classes.paperWrapper]: true,
                        //   [classes.fixedWrapper]: fixedSlideArray[index],
                        // })}
                        // style={{
                        //   top: slideYPosArray[index], // used to stop scroll flicker on fixed change
                        //   // top: 0,
                        //   opacity: slideOpacityArray[index],
                        //   //   slideYPosArray[index + 1] > windowHeight ? 1 : 0, // controls fade out
                        // }}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Paper variant="blur" sx={styles.blurPaper}>
                          <Markdown markdown={item.markdown} />
                          {/* <Typography variant={variant}>{text}</Typography> */}
                        </Paper>
                      </Grid>
                    </Grid>
                  );
                })}

              {false && introAnimationComplete && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    ...styles.slide,
                    ...styles.paperWrapper,
                  }}
                  // className={clsx({
                  //   [classes.slide]: true,
                  //   [classes.paperWrapper]: true,
                  // })}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Paper variant="blur" sx={styles.blurPaper}>
                    <LogoCollection invert />
                  </Paper>
                </Grid>
              )}
            </Grid>

            {introAnimationComplete && (
              <Grid
                item
                container
                sx={{
                  ...styles.logoWrapper,
                  ...styles.whiteSlide,
                  ...styles.footerSlide,
                }}
                // className={clsx({
                //   // [classes.slide]: true,
                //   [classes.logoWrapper]: true,
                //   [classes.whiteSlide]: true,
                //   [classes.footerSlide]: true,
                // })}
                direction="column"
                justifyContent="flex-end"
              >
                <LogoCollection />
              </Grid>
            )}

            {introAnimationComplete && (
              <Grid
                item
                container
                sx={{
                  ...styles.slide,
                  ...styles.whiteSlide,
                  ...styles.footerSlide,
                }}
                // className={clsx({
                //   [classes.slide]: true,
                //   [classes.whiteSlide]: true,
                //   [classes.footerSlide]: true,
                // })}
                direction="column"
                justifyContent="flex-end"
              >
                <Footer pageData={pageData} includeCta threeJsPage />
              </Grid>
            )}
          </Grid>
        </PageLayout>
      </>
    );
  }

  // fallback for no WebGL support
  return (
    <>
      {genHelmet(pageData, {
        type: "page",
        // siteData: pageData,
        path: props.path,
      })}
      <PageLayout location={props.location} hideFooter sx={styles.root}>
        <Box
          sx={{
            ...styles.slide,
            ...styles.paperWrapper,
            ...styles.noWebGL,
            backgroundImage: `url(${pageData.coverImage.banner.images.fallback.src})`,
          }}
          // className={clsx({
          //   [classes.slide]: true,
          //   [classes.paperWrapper]: true,
          //   [classes.noWebGL]: true,
          // })}
          alignItems="center"
          justifyContent="center"
          // style={{
          //   backgroundImage: `url(${pageData.coverImage.banner.images.fallback.src})`,
          // }}
        >
          <Paper variant="blur" sx={styles.blurPaper}>
            <Typography variant="h3">
              Unfortuantely your device does not support WebGL. Please enable it
              to see this page.
            </Typography>
          </Paper>
        </Box>
      </PageLayout>
    </>
  );
};

export default Digital;

export const pageQuery = graphql`
  {
    contentfulPage(slug: { eq: "digital" }) {
      slug
      headline
      title
      callToActionHeadline
      callToActionText {
        callToActionText
      }
      callToActionButtonText
      coverImage {
        title
        description
        gatsbyImageData
        banner: gatsbyImageData(width: 1200, formats: JPG, sizes: "1200px")
        thumbnail: gatsbyImageData(width: 600, formats: JPG, sizes: "600px")
      }
      heroVideoPackage {
        duration
        videoFirstFrame {
          title
          description
          gatsbyImageData
        }
        videoLastFrame {
          title
          description
          gatsbyImageData
        }
        videoSmall {
          file {
            url
          }
        }
        videoMedium {
          file {
            url
          }
        }
        videoLarge {
          file {
            url
          }
        }
      }
      contentCollection {
        xs
        sm
        md
        lg
        xl
        align
        markdown {
          childMarkdownRemark {
            html
          }
        }
      }
      posts {
        ... on ContentfulProject {
          headline
          slug
          title
          category
          internal {
            type
          }
          coverImage {
            title
            description
            gatsbyImageData
          }
        }

        ... on ContentfulPost {
          headline
          slug
          title
          internal {
            type
          }
          coverImage {
            title
            description
            gatsbyImageData
          }
        }
      }
    }
  }
`;
