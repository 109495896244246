import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";

import theme from "../gatsby-theme-material-ui-top-layout/theme";

const styles = {
  root: {
    zIndex: 5000,
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(2),
    background: "rgba(255,255,255,0.85)",
    "& button": {
      color: "#000",
    },
  },
  slider: {
    display: "flex",
  },
};

const ThreeAnimationHelper = (props) => {
  const { scene, camera } = props;

  const [showHelper, setShowHelper] = useState(false);
  const [position, setPosition] = useState(new Array(3));

  //set position on camera change
  useEffect(() => {
    if (!camera || !camera.position) return;

    if (
      camera.position.x === position[0] ||
      camera.position.y === position[1] ||
      camera.position.z === position[2]
    )
      return;

    setPosition(camera.position.toArray());
  }, [camera.position.x, camera.position.y, camera.position.z]);

  const handleSetPosition = (ev, index) => {
    setPosition(
      Object.assign(position.slice(), { [index]: Number(ev.target.value) })
    );
  };

  useEffect(() => {
    if (!position || !position[0]) return;
    camera.position.fromArray(position);
  }, [position]);

  return (
    <Box sx={styles.root}>
      {!showHelper && (
        <Button variant="outlined" onClick={() => setShowHelper(true)}>
          Show Scene Helper
        </Button>
      )}
      {showHelper && (
        <Card variant="outlined" sx={styles.card}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  type="number"
                  size="small"
                  label="X"
                  variant="outlined"
                  value={position[0]}
                  onChange={(ev) => handleSetPosition(ev, 0)}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  size="small"
                  label="Y"
                  variant="outlined"
                  value={position[1]}
                  onChange={(ev) => handleSetPosition(ev, 1)}
                />
              </Grid>
              <Grid item>
                <TextField
                  type="number"
                  size="small"
                  label="Z"
                  variant="outlined"
                  value={position[2]}
                  onChange={(ev) => handleSetPosition(ev, 2)}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button variant="outlined" onClick={() => setShowHelper(false)}>
              Hide
            </Button>
            <Button variant="outlined" onClick={() => console.log(camera)}>
              Log Cam
            </Button>
            <Button variant="outlined" onClick={() => console.log(scene)}>
              Log Scene
            </Button>
          </CardActions>
        </Card>
      )}
    </Box>
  );
};

export default ThreeAnimationHelper;
