import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useCallback,
} from "react";
import { setupScene, testWebGL } from "../actions/sceneActions/sceneLoader";

// default render settings

// if (!renderSettings) {
//   renderSettings = {
//     type: "WEBGL", // ENUMs: 'RECT_LIGHT', 'SHADER', 'TOON', 'GLASS', WEBGL
//     bloomParams: {
//       // exposure: 1,
//       bloomStrength: 0.65,
//       bloomThreshold: 0,
//       bloomRadius: 0.5,
//     },
//     controlsType: "ORBIT_CONTROLS",
//     orbitControlsSettings: {
//       center: [0, 0, 0],
//       minDistance: 500,
//       maxDistance: 5000,
//       panSpeed: 0,
//       maxPolarAngle: Math.PI * 0.55,
//       // minAzimuthAngle: Math.PI,
//       // maxAzimuthAngle: Math.PI * 0.5,
//       // outputEncoding: 3001 //3007 //THREE.GammaEncoding //THREE.sRGBEncoding
//     },
//     // toonBorder: {
//     //   color: [255,255,255],
//     //   thickness: 0.005
//     // },
//   };
// }

/**
 *
 * @param {String} sceneId - Scene ID for the BVS file
 * @param {import("react").Ref} vpRerence - viewport canvas reference
 * @param {Array} controlsCenter - [x,y,z] for control center
 * @param {Boolean} startAnimation - boolean to start animation or not
 * @param {RenderSettingsObject} renderSettings - see hook file for example
 * @returns
 */
const useSceneLoader = ({
  sceneId,
  vpReference,
  controlsCenter,
  startAnimation,
  renderSettings,
}) => {
  const [totalAssets, setTotalAssets] = useState(null);
  const [loadedAssets, setLoadedAssets] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [intersect, setIntersect] = useState();
  const [outerScene, setOuterScene] = useState(); // used outer prefix to differentiate from scene returned in functions below
  const [outerCamera, setOuterCamera] = useState();
  const [webGLSupported, setWebGLSupported] = useState(true);
  // const [update, setUpdate] = useState();
  // const [render, setRender] = useState();
  const loadedAssetsRef = useRef();
  loadedAssetsRef.current = loadedAssets;
  const animationRef = useRef();
  const renderRef = useRef();
  const updateRef = useRef();
  const destroyRef = useRef();

  // helper functions
  const addAsset = (uuid) => {
    loadedAssetsRef.current++;
    setLoadedAssets(loadedAssetsRef.current);
  };

  // animation
  const animate = () => {
    if (updateRef.current) updateRef.current();
    if (renderRef.current) renderRef.current();
    animationRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (!startAnimation) return;
    console.log("starting animation");
    setIsAnimating(true);
    animationRef.current = requestAnimationFrame(animate);
  }, [startAnimation]);

  // track loading
  useEffect(() => {
    if (isLoaded) return;
    if (totalAssets === null) return;
    setProgress(loadedAssets / totalAssets);
    if (loadedAssets >= totalAssets) {
      setIsLoaded(true);
      if (renderRef.current) renderRef.current();
    }
  }, [loadedAssets, totalAssets, isLoaded]);

  // setup the scene
  const runSceneSetup = useCallback(async () => {
    const webGLTest = await testWebGL();
    if (!webGLTest) return setWebGLSupported(false);

    const { current: viewport } = vpReference;
    const {
      scene,
      camera,
      render,
      animate,
      renderer,
      controls,
      raycaster,
      destroy,
      update,
    } = await setupScene(vpReference, {
      sceneId,
      renderSettings,
      controlsCenter: controlsCenter ? controlsCenter : [0, 0, 0],
      setupLoadingManager: (bvs) => {
        if (!bvs) return setTotalAssets(0);
        setTotalAssets(bvs.geometryUuids.length + bvs.imageUuids.length);
      },
      onAssetLoad: (uuid) => {
        addAsset(uuid);
      },
    });

    // INTERSECTS & CLICKING
    const mouse = { x: 0, y: 0 };
    // TODO - need a data structure for this
    const clickableMeshUuids = [
      "8675B77D-CFE5-487F-98A3-4ACE3C0279AF",
      "9166C8E7-8B1D-491A-9F74-4AADE8F44EC3",
      "67D746C1-B068-40C2-9C4A-6F4DDA978862",
      "8B5D90F8-F1A3-43CA-B0B4-394AC197F560",
    ];
    const clickableMeshes = [];
    scene.traverse((obj) => {
      if (clickableMeshUuids.includes(obj.uuid)) clickableMeshes.push(obj);
    });

    const onMouseMove = (ev) => {
      if (ev.targetTouches) {
        if (ev.targetTouches.length > 1) return;
        mouse.x = (ev.targetTouches[0].clientX / viewport.clientWidth) * 2 - 1;
        mouse.y =
          -(ev.targetTouches[0].clientY / viewport.clientHeight) * 2 + 1;
      } else {
        mouse.x = (ev.clientX / viewport.clientWidth) * 2 - 1;
        mouse.y = -(ev.clientY / viewport.clientHeight) * 2 + 1;
      }

      if (raycaster) {
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(clickableMeshes);
        if (intersects.length > 0) {
          setIntersect(intersects[0]);
        } else setIntersect(null);
      }
    };

    if (typeof window !== undefined) {
      window.addEventListener("mousemove", onMouseMove, false);
      window.addEventListener("touchstart", onMouseMove, false);
    }

    // add functions for access outside this callback
    // updateRef.current = update; // turned this  off for now - need to fix this for animation withing scenes
    renderRef.current = render;
    destroyRef.current = () => {
      if (typeof window !== undefined) {
        window.removeEventListener("mousemove", onMouseMove, false);
        window.removeEventListener("touchstart", onMouseMove, false);
      }
      destroy();
    };
    setOuterScene(scene);
    setOuterCamera(camera);
  });

  useEffect(() => {
    if (!webGLSupported) return;
    const viewport = vpReference.current;
    // console.log("setting up scene");

    runSceneSetup();

    return () => {
      // console.log("cleaning up");

      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }

      if (destroyRef.current) destroyRef.current();
      // }
    };
  }, [webGLSupported]);

  return {
    isLoaded,
    intersect,
    progress,
    isAnimating,
    webGLSupported,
    camera: outerCamera,
    scene: outerScene,
    setUpdate: (updateFunction) => {
      updateRef.current = updateFunction;
      // console.log(updateRef);
    },
    startAnimation: () => {
      console.log("starting animation");
      animationRef.current = requestAnimationFrame(animate);
      setIsAnimating(true);
    },
    pauseAnimation: (bool) => {
      console.log("pausing animation");
      cancelAnimationFrame(animationRef.current);
      setIsAnimating(false);
    },
  };
};

export default useSceneLoader;
